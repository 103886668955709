@import 'abstracts';

app-auction-order-actions {
  .subtitle {
    padding: 14px;
  }

  .form-line {
    display: flex;

    .form-group {
      padding: 0 14px;
      width: 50%;

      &:last-child {
        padding-right: 0;
      }

      &:not(:last-child) {
        padding-left: 0;
        border-right: 1px solid $gray;
      }
    }
  }

  cap-input label {
    margin-bottom: 4px;
  }

  .auction-order-body {
    border-bottom: 1px solid $gray;
    padding: 14px;
  }

  .auction-order-footer {
    display: flex;
    width: 100%;
    padding: 14px;

    cap-button {
      width: 50%;

      button.cap-button {
        padding: 2px 0;
      }

      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }
}
